<template>
  <div :key="isEnglish" class="liveagent">
    <viiz-header :live-agent="liveAgent" />
    <main id="liveagent-login-page">
      <section class="login-section">
        <div class="logon">
        <img class="profile-pic" src="/res/img/user/user.svg" />
            <button id="liveagent-login-submit" class="uppercase" @click="handleLogin">
              {{ getString('btn_login') }}
            </button>
            <a class="setLanguage" @click="toggleLanguage"> {{ getString(`btn_lang_${getLang()}`) }} </a>
        </div>
          <p v-if="liveAgent.login.error_message" class="text-danger">{{ getString(liveAgent.login.error_message) }}</p>
      </section>
    </main>
  </div>
</template>

<script>
import ViizHeader from './ViizHeader'
import Lang from '../i18n'

export default {
  components: { ViizHeader },
  props: {
    liveAgent: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      isEnglish: true,
    }
  },
  mounted() {
    const params = new URL(window.location).searchParams
    const lang = params.get('lang')

    if (lang) {
      Lang.setLanguage(lang)
      this.$set(this, 'isEnglish', Lang.getLanguage() === 'en')
    }
  },
  methods: {
    getLang() {
      return Lang.getLanguage()
    },
    getString(key) {
      return Lang.string(key)
    },
    toggleLanguage: function () {
      let lang = Lang.getLanguage() === 'en' ? 'fr' : 'en'
      Lang.setLanguage(lang)
      this.$set(this, 'isEnglish', lang === 'en')
    },
    handleLogin() {
      window.location = `/oauth/login?lng=${Lang.getLanguage() || 'en'}`
    }
  },
}
</script>
<style scoped>
.uppercase {
  text-transform: uppercase;
}

.login-section {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-width: 350px;
}

.logon {
  display: flex;
  gap: 1rem;
}
</style>
