let self = {}
let lang = 'en'

const texts = {
  en: {
    header_request_complete: 'Request Completed',
    btn_login: 'Log on',
    btn_submit: 'Submit',
    btn_continue_to_login: 'Continue to Login',
    btn_lang_fr: 'English',
    btn_lang_en: 'Français',
    img_loading_alt: 'Validating credentials... please wait...',
    message_processing_request: 'Processing your request',
    error_unexpected_error: 'Unexpected failure. Please notify your supervisor.',
    error_login_401: '401 Invalid Credentials',
    error_login_403: '403 Not Authorized',
    error_login_503: '503 SIP Service Unavailable. Try again or contact your supervisor if the problem persists.',
  },
  fr: {
    header_request_complete: 'Demande terminée',
    btn_login: 'Ouvrir la session',
    btn_submit: 'Soumettre',
    btn_continue_to_login: 'Continuer à se connecter',
    btn_lang_fr: 'English',
    btn_lang_en: 'Français',
    img_loading_alt: "Validation des informations d'identification ... veuillez patienter ...",
    message_processing_request: 'Traitement de votre demande',
    error_unexpected_error: 'Échec inattendu. Veuillez informer votre superviseur.',
    error_login_401: '401 Information invalide',
    error_login_403: '403 Non autorisé',
    error_login_503: '503 Service SIP indisponible. Réessayez ou contactez votre superviseur si le problème persiste.',
  },
}

self.setLanguage = function (code) {
  if (Object.keys(texts).includes(code)) {
    lang = code
  }
}

self.getLanguage = function () {
  return lang + ''
}

self.string = function (key, params = {}) {
  let str = texts[lang][key] || key
  const keys = Object.keys(params)

  keys.forEach((k) => {
    const find = new RegExp(`{{${k}}}`, 'g')
    str = str.replace(find, params[k])
  })

  return str
}

export default self
