'use strict'
import { log } from '../lib/logger'
window.addEventListener('error', function(evt) {
  log(
    3,
    evt.message +
      "' from " +
      evt.filename +
      '?' +
      evt.lineno +
      ':' +
      evt.colno +
      (evt.error && evt.error.stack ? '\n' + evt.error.stack : '')
  )
})

import Vue from 'vue'
import vClickOutside from 'v-click-outside'
import common from '../lib/liveagent-ui-common'
import modal from '../lib/liveagent-ui-modal'
import session from '../lib/liveagent-ui-session'
import Mousetrap from 'mousetrap'
import Login from '../components/Login'
import store from '../store'
import bootstrap from '../styles/bootstrap.css' // eslint-disable-line no-unused-vars
import style from '../styles/liveagent.css' // eslint-disable-line no-unused-vars
import paperdashboard from '../styles/paper-dashboard.css' // eslint-disable-line no-unused-vars
import componentStyle from './app-login.css' // eslint-disable-line no-unused-vars

Vue.use(vClickOutside)

const liveAgent = {}
liveAgent.log = log
liveAgent.common = common
liveAgent.modal = modal
liveAgent.session = session
liveAgent.login = {
  error_message: '',
}
session.validate('login')

liveAgent.mousetrap = Mousetrap

store.commit('setLiveAgent', liveAgent)

new Vue({
  el: '#app',
  store,
  data: { liveAgent },
  render: h => h(Login, { props: { liveAgent: liveAgent } }),
})

liveAgent.session.socket.on('unlock', unlock)

function unlock() {
  window.location.href = '/admin'
}

switch (window.location.hash) {
  case '#booted':
    liveAgent.login.error_message =
      'You have been returned to the login screen because your session was killed by an administrator.'
    break
  case '#issues':
    liveAgent.login.error_message =
      'Your session has ended due to server issues. Please try again and contact your supervisor if the problem persists.'
    break
  case '#invalid':
    liveAgent.login.error_message = 'You have been returned to the login screen because your session became invalid.'
    break
  case '#migrated':
    liveAgent.login.error_message = 'This user has logged in at another location.'
    break
}

window.location.hash = ''
